import React from "react";
import Navbar from "../../helpers/Navbar";
import Footer from "../../helpers/Footer";
import { NavLink} from "react-router-dom";
import "./demopage.css";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Demohomepage = () => {
  const themes = [
    { id: "theme1", previewId: "dug8qkd2o", name: "Pink Royale",image:"https://res.cloudinary.com/dtg40vnf7/image/upload/v1730209923/aamantran%20assets/pink15_q2zxlb.jpg" },
    { id: "theme2", previewId: "hddtkt3dq", name: "Timeless Grace",image:"https://res.cloudinary.com/depixc8dq/image/upload/v1726389611/aamantran%20assets/theme2_lwfsob.jpg" },
    { id: "theme3", previewId: "7en0knkko", name: "Modern Chic",image:"https://res.cloudinary.com/dtg40vnf7/image/upload/v1730452947/aamantran%20assets/bluenew_wccysb.jpg" },
    { id: "theme4", previewId: "qsy6pz91z", name: "Royal Blossom",image:"https://res.cloudinary.com/depixc8dq/image/upload/v1718954818/aamantran%20assets/theme11_cwszsl.jpg" },
    { id: "theme5", previewId: "5rx5jrugk	", name: "Boho Style",image:"https://res.cloudinary.com/depixc8dq/image/upload/v1718954817/aamantran%20assets/printbj3_ni2jux.jpg" },
    { id: "theme6", previewId: "iirzkdwly", name: "Ethereal Blue",image:"https://res.cloudinary.com/depixc8dq/image/upload/v1718954800/aamantran%20assets/print7_dicfa0.jpg" },
    // { id: "theme7", previewId: "tpurjnrb3", name: "Minimalist Green",image:"https://res.cloudinary.com/dtg40vnf7/image/upload/v1730452947/aamantran%20assets/pinkf4_tux8ia.jpg"  "https://res.cloudinary.com/depixc8dq/image/upload/v1718954800/aamantran%20assets/print7_dicfa0.jpg Ethereal Blue"},
    { id: "theme7", previewId: "npiiilwdw", name: "Elegant Floral",image:"abba.jpg" },
  ];

  return (
    <>
      <Navbar />
      <h2 className="explore-theme-heading">Explore Stunning Invitation Themes</h2>
      <div className="demo-container">
        {themes.map((theme, index) => (
          <div key={index} className="theme-card">
            <div className="theme-image">
              <img src={theme.image} alt={theme.name} />
              <div className={`theme-name-overlay  ${theme.name === "Ethereal Blue" ? "white-text" : ""} ` }>{theme.name}</div>
            </div>
            <NavLink to={`/preview/${theme.previewId}?theme=${theme.id}`} className="view-demo-button">
              View Demo  <ArrowForwardIosIcon  className="view-demo-icon"/>
            </NavLink>
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
};

export default Demohomepage;

import React from 'react'
import ShareIcon from '@mui/icons-material/Share';
import { RWebShare } from "react-web-share";
import dayjs from 'dayjs';
const FSharing = ({brideName,groomName,weddingDate,userCode,group}) => {


  const formatDate = (inputDate) => {
    const date = dayjs(inputDate);
    const formattedDate = date.format('DD MMMM YYYY');
    return formattedDate
};
const validGroups = ["g1160a", "g2330b", "g3140c", "g5210d"]; // Allowed group values
const groupParam = validGroups.includes(group) ? `?group=${group}` : ""; // Validate group
   
  return (
        <RWebShare
        data={{

          text:((groomName!=="Groom Name")  && (brideName!=="Bride Name"))?
          `Hello!

We cordially invite you along with your family to celebrate the beginning of our new life! ❤️🎊

💍 ${groomName}  Weds ${brideName} 

${weddingDate!=="dd-mm-yyyy"?`📅Date:${formatDate(weddingDate)}`:""}   

💌 View e-invite:`:  
 `Hello!

We cordially invite you along with your family to celebrate the beginning of our new life! ❤️🎊

💌 View e-invite:`,
title: "Wedding Invitation", 
url:`https://testbackend.aamantran.link/${userCode}${groupParam}`,
        }
        }
      >
      <div className="share-div">
      <ShareIcon  className='share-icon' /> 
      <span>Share This Invitation</span>
      </div>
      
      </RWebShare>
  )
}

export default FSharing